// password-validator_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["password", "confirm", "error"];
  
    connect() {
      console.log("Password validator connected");
    }
  
    validatePassword() {
      const password = this.passwordTarget.value;
      const confirmPassword = this.confirmTarget.value;

      const isValid = this.checkPasswordValidity(confirmPassword);

      console.log(isValid)
  
      if (isValid && password === confirmPassword) {
        // As senhas são válidas e iguais, limpe as mensagens de erro.
        this.errorTarget.innerHTML = "";
      } else {
        // Exiba mensagens de erro.
        this.displayerror(isValid, password, confirmPassword);
      }
    }
  
    checkPasswordValidity(password) {
      // Adicione seus critérios de validação aqui
      const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
      const numberRegex = /\d/;
      const uppercaseRegex = /[A-Z]/;
  
      return (
        password?.length >= 8 &&
        specialCharRegex.test(password) &&
        numberRegex.test(password) &&
        uppercaseRegex.test(password)
      );
    }
  
    displayerror(isValid, password, confirmPassword) {
      const error = [];
  
      if (!isValid) {
        error.push("🔒 A senha não atende aos requisitos.");
      }
  
      if (password?.length < 8) {
        error.push("❌ A senha deve ter pelo menos 8 caracteres.");
      }
  
      if (!/[A-Z]/.test(password)) {
        error.push("❌ A senha deve conter pelo menos uma letra maiúscula.");
      }
  
      if (!/\d/.test(password)) {
        error.push("❌ A senha deve conter pelo menos um número.");
      }
  
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        error.push("❌ A senha deve conter pelo menos um caractere especial.");
      }
  
      if (password !== confirmPassword) {
        error.push("❌ As senhas não coincidem.");
      }

      if(password && confirmPassword){
        this.errorTarget.innerHTML = `<p>${error.join("</p><p>")}</p>`;
      } 
    }
  }
