/*
	Existem muitas maneiras de dizer que esse codigo ficou ruim, talvez nao exatamente o codigo, mas sim a abordagem que usei para resolver o problema, mas na pressão temos que fazer alguns milagres. Eu ainda quero parar para poder melhorar isso aqui e espero que consigo. Caso voce esteja vendo esse comentário, é porque eu não consigo e espero que Deus te ajude!

since 2023.09.15
*/ 

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["info", "impacto", "probabilityButton", "resultLabel", "probabilities", "classificationLabel", 'probability', 'impact'];

	connect() {
		this.fetchClassificationRisks();
	}

	fetchClassificationRisks(){
		fetch("/classification-risks").then(response => response.json()).then(data => {
        this.updateResultLabel(data)
      })
      .catch(error => {
        console.error("Erro ao buscar valores do banco:", error)
      });
	}

	async displayInfo(event) {
		const select = event?.currentTarget;
		const selectedValue = select?.value;

		if(selectedValue){
			const infoRisco = document.getElementById('risco')
			const infoRiscoMatriz = document.getElementById('matrizRisco')
			infoRisco.style.display = 'block'
			infoRiscoMatriz.style.display = 'block'
			this.fetchData('risks-client',selectedValue)
			.then(data => {
				this.updateInfo(data);
			 })
			.catch(error => {
				console.error(error);
				this.infoTarget.innerHTML = ""
			});
		}else {
			return;
		}
	}

	async fetchData(resource, id) {
		if(id == '') { return }
		 
		const uri = id ? `/${resource}/${id}.json` : `/${resource}`

		const response = await fetch(`${uri}`);

		if (!response.ok) { 
			throw new Error("Erro ao buscar informações do servidor") 
		}

		return response.json();
	}


	 probabilities(probability) {
			let data = ['Muito Alta', 'Alta', 'Média', 'Baixa', 'Muito Baixa']
		 	
			data = data.map((p) => {
				let colorDefault = ''
				if(p == probability){
					colorDefault = '#c5c5c5'
				}
					return `
					<button type="button" class="btn btn-default" style="background: ${colorDefault}" data-risk-target="probabilities" value="${p}" id="probabilities" data-action="click->risk#toggle">${p}</button>
					`;
				}).join('');

			return `<div class="col-12" style="margin-top: 20px">
								<p>Probabilidade: </p>
								<div class="btn-group">
									${data}
								</div
							</div`
	}

	impacts(impact) {
		let data = ['Muito Alto', 'Alto', 'Médio', 'Baixo', 'Muito Baixo']

		data = data.map((p) => {
			let colorDefault = ''
			if(p == impact){
				colorDefault = '#c5c5c5'
			}
			return `
					 <button type="button" class="btn btn-default" style="background: ${colorDefault}" value="${p}" id="impacts" data-risk-target="impact"  data-action="click->risk#toggle">${p}</button>
					`;
				}).join('');

			return `<div class="col-12" style="margin-top: 20px">
								<p>Impacto: </p>
								<div class="btn-group">
									${data}
								</div
					</div`
	}


	updateInfo(data) {
		const colors = {"Muito Alto": "#FF5B5B", "Alto": "#FFAFAF", "Médio": "#FFC000", "Baixo": "#9BC2E6", "Muito Baixo": "#A9D08E"}
		const infoTarget = this.infoTarget;
		infoTarget.innerHTML = "";

		const content = `
        	<div class="col-12"> <p style="color: #9fa4a9; margin-top: 20px">Descrição: ${data.description}</p> </div>
        	<div class="col-12"> <p style="color: #9fa4a9;">Observação: ${data.obs}</p> </div>
		`
		const contentRisk= `
            <div class="col-6" style="margin-top: 15px">
                <p>Classificação de risco:</p>
            </div>
            <div class="btn-group">
							<button type="button" data-risk-target="classificationLabel" class="btn btn-default" style="background: ${colors[data.risk_classficication]}"><p data-risk-target="resultLabel">${data.risk_classficication}</p></button>
            </div>
			`
			
		infoTarget.insertAdjacentHTML("beforeend", content)
		infoTarget.insertAdjacentHTML("beforeend", this.impacts(data.impacto))
		infoTarget.insertAdjacentHTML("beforeend", this.probabilities(data.probability))
		infoTarget.insertAdjacentHTML("beforeend", contentRisk)
	}

		submit(event){
			event.preventDefault();
			const form = event.target.closest("form");

			fetch('/risks', {
				method: 'POST',
				body: new FormData(form)
			}).then(response => {
				console.log(response)
				if (response.ok) {
					this.close();
				}
				});
		}

		close(){
			return
		}


		calcularRiscoEClassificar(risco, impacto) {
			const niveisRisco = {
				"Muito Baixa": 0,
				"Baixa": 1,
				"Média": 2,
				"Alta": 3,
				"Muito Alta": 4
			};

			const niveisImpacto = {
				"Muito Baixo": 0,
				"Baixo": 1,
				"Médio": 2,
				"Alto": 3,
				"Muito Alto": 4
			};

			const indiceRisco = niveisRisco[risco];
			const indiceImpacto = niveisImpacto[impacto];
			
			if (indiceRisco !== undefined && indiceImpacto !== undefined) {
				const valorRisco = (indiceImpacto + 1) * (indiceRisco + 1);
				
				if (valorRisco >= 1 && valorRisco <= 2) {
					return "Muito Baixo";
				} else if (valorRisco >= 3 && valorRisco <= 4) {
					return "Baixo";
				} else if (valorRisco >= 5 && valorRisco <= 9) {
					return "Médio";
				} else if (valorRisco >= 10 && valorRisco <= 14) {
					return "Alto";
				}	else if (valorRisco >= 15 && valorRisco <= 25) {
					return "Muito Alto";
				} else {
					return "Valor de risco inválido.";
				}
			} else {
				return "Níveis não calculados. Clique novamente!";
			}
		}


	  updateResultLabel(optionClicked) {
			const colors = {"Muito Alto": "#FF5B5B", "Alto": "#FFAFAF", "Médio": "#FFC000", "Baixo": "#9BC2E6", "Muito Baixo": "#A9D08E"}
	
			let impacto = '';
			let probabilidade = '';

			if (optionClicked.target.id == "impacts") {
				 this.probabilitiesTargets.map(button => {
					if (button.style.background == 'rgb(197, 197, 197)') {
						 impacto = optionClicked.target.value;
						 return probabilidade = button.value
					}
				})
			} else if (optionClicked.target.id == "probabilities"){
				this.impactTargets.map(button => { 
					if (button.style.background == 'rgb(197, 197, 197)') { 
						probabilidade = optionClicked.target.value;
						return impacto = button.value
					}
				})
			}


			this.classificationLabelTarget.style.background = ''
//			ordem dos argumentos importa
			this.classificationLabelTarget.style.background = colors[this.calcularRiscoEClassificar(probabilidade, impacto)]
			this.classificationLabelTarget.textContent = `${this.calcularRiscoEClassificar(probabilidade, impacto)}`;
		}

	clearActiveColors(container, exCurrent) {
			const buttons = container.querySelectorAll(".btn");
			buttons.forEach(button => {
					button.style.background = '';
			});

			if (exCurrent) {
					exCurrent.style.background = '';
			}
	}

	toggle(event) {
			const button = event.currentTarget;
			const container = button.parentNode;

			if (button.classList.contains("btn-default")) {
					this.clearActiveColors(container, button);
					button.style.background = '#c5c5c5';
			} else {
					button.style.background = '';
			}
			this.updateResultLabel(event)
	}
}

