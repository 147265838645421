import React,{ useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Notify from '../utils/Notify.js'


const ModalRiskClient = ({impacts, probabilities}) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    description: '',
    obs: '',
    code: '',
    impact_client_id: '',
    probability_client_id: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const cleanFormData = () => {
    Object.entries(formData).map((f) => {
      setFormData((prevData) => ({ ...prevData, [f[0]]: ''}))
    })
  }

  const save = async (e) => {
    try {
      const response = await axios.post('/risks-client', {'risk_client': formData});
      Notify("success")
      handleClose();
      cleanFormData();
    } catch (error) {
      console.error('Erro ao criar o registro:', error);
      Notify("error", error.message)
    }
  };

  return (
    <>
      <Button variant="outline-secondary" onClick={handleShow}>
        + Novo
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de Risco</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group 
                className="mb-3" 
                controlId="exampleForm.ControlInput1">
              <Form.Label>Código</Form.Label>
              <Form.Control
                name="code"
                value={formData.code}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group 
                className="mb-3" 
                controlId="exampleForm.ControlInput1">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Probabilidade</Form.Label>
              <Form.Select aria-label="Default select example" 
                  name="probability_client_id" 
                  className="form-control select2"
                  onChange={handleInputChange}
                  value={formData.probability_client_id}
                >
                <option>Selecione uma opção</option>
                {probabilities?.map((probability) => (
                  <option value={probability.value}>{probability.label}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Impacto</Form.Label>
              <Form.Select 
                  aria-label="Default select example" 
                  name="impact_client_id" 
                  className="form-control select2"
                  onChange={handleInputChange}
                  value={formData.impact_client_id}
              >
                <option>Selecione uma opção</option>
                {impacts?.map((impact) => (
                  <option value={impact.value}>{impact.label}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Observação</Form.Label>
              <Form.Control as="textarea" 
                rows={3} 
                name="obs"
                value={formData.obs}
                onChange={handleInputChange}          
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={() => save()}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalRiskClient;
