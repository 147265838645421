import React, { useEffect, useState, useMemo } from 'react';
import Select from 'react-select'
import axios from 'axios';

const SelecionarMult = ({endpoint, selected, id, idRisco, resource, action, field, isMult, client_id, selectedDefault, setParamsActions, paramsActions}) => {
  const [options, setOptions] = useState([]);  
  const [value, setValue] = useState([]); 
  const [loadOptions, setLoadOptions] = useState(true)

  const onChange = (option) => {
    setValue(option);
    if (option) {
      axios.put(`/${resource}}?id_activity=${id}`, {  [field]: option?.map((d) => d.value) })
        .then((res) => {
        })
        .catch((error) => {
          console.error("Ocorreu um erro durante a atualização:", error);
        });
    }
  }



  useEffect(() => {
    if (setParamsActions && value && Array.isArray(value)) {
      setParamsActions(oldValue => {
        // Se value estiver vazio, não fazer alterações
        if (value.length === 0) {
          return oldValue;
        }

        const valueRiskId = value[0].risk_id;
  
        try {
          const groupIndex = oldValue?.findIndex(group => 
            group?.some(item => item.risk_id === valueRiskId)
          );

          if (groupIndex !== -1) {
            return oldValue.map((group, index) => {
              if (index === groupIndex) {
                return value;
              }
              return group;
            });
          } else {
            // Se o risk_id é novo, adicionar um novo grupo
            return [...oldValue, value];
          } 
        } catch (error) {
          console.log(error)  
        }
      });
    }
  }, [value, setParamsActions]);



  useEffect(() => {
    if (value?.length > 0) { return }
    if(selectedDefault) {
      setValue(selectedDefault)
    } else {
      setValue(selected)
    }
  }, [selectedDefault, selected])


  const ids = value?.map((d) => d.value )

  useEffect(() => {
    if(loadOptions) {
      axios.get(`/${endpoint}?client_id=${client_id}`).then((res) => {
        const updatedData = res.data.map(ele => ({ ...ele, risk_id: idRisco }));

        setOptions(updatedData);
      }).catch((error) => {
        console.log("Ocorreu um erro na requisição,", error)
      })
      setLoadOptions(false)
    }
  }, [loadOptions])


  return (
    <>
      <Select
        key={id}
        placeholder={"Selecione uma opção"}
        isMulti
        options={options}
        value={value}
        onChange={onChange}
        onMenuOpen={() => setLoadOptions(true)}
      />
      {ids?.map((id, index) => (
        <>
          <input key={index} type="hidden" name={field} value={id} />
        </>
      ))}
    </>
  )
}

export default SelecionarMult;
