import Swal from 'sweetalert2'

const Notify = (type, msg = 'Seu formulário foi enviado com sucesso.') => {
  const title = {
    success: "Opa, tudo certo!",
    error: "Algo deu errado!"
  }

  Swal.fire({
    title: title[type],
    text: msg,
    icon: type
  });
}

export default Notify;
