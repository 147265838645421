import React, { useState, useEffect } from 'react';
import MultSelect from './MultSelect.js'
import ModalActionMitigating from './modais/ModalActionMitigating.js'
import ModalRiskClient from './modais/ModalRiskClient.js'
import Select from 'react-select'
import styled from 'styled-components'

import axios from 'axios';

function RiskManagement({selected, id, client_id, action, risk_client_ids, ids_risk, impacts, probabilities}) {

  const [campos, setCampos] = useState(ids_risk);
  const [selecaoRisco, setSelecaoRisco] = useState(risk_client_ids); 
  const [loadOptions, setLoadOptions] = useState(true)
  const [options, setOptions] = useState([]);
  const [infoRisco, setInfoRisco] = useState({});
  const [infoRiscoActivity, setInfoRiscoActivity] = useState({});

  const [impacto, setImpacto] = useState(null);
  const [probabilidade, setProbabilidade] = useState(null);
  const [classificacao, setClassificacao] = useState('Não Classificado');
  const [paramsActions, setParamsActions] = useState([]);
  const [selectedButton, setSelectedButton] = useState({});
  const [currentRisco, setCurrentRisco] = useState(null);
  const [updateButton, setUpdateButton] = useState(true);

  const [key, setKey] = useState(Date.now());

  const [accordionStates, setAccordionStates] = useState(null); 
  const [editRisco, setEditRisco] = useState(false);

  const niveisProbabilidade = {
    "Muito Baixa": 0,
    "Baixa": 1,
    "Média": 2,
    "Alta": 3,
    "Muito Alta": 4
  };

  const niveisImpacto = {
    "Muito Baixo": 0,
    "Baixo": 1,
    "Médio": 2,
    "Alto": 3,
    "Muito Alto": 4
  };

  const accordionState = (index) => {
    if(index === accordionStates) {
      return setAccordionStates(null)
    }
    return setAccordionStates(index)
    setKey(Date.now())
  }

  
  const calcularRiscoEClassificar = (probabilidade, impacto) => {
    const valorRisco = (probabilidade + 1) * (impacto + 1);

    if (valorRisco >= 1 && valorRisco <= 2) {
      return "Muito Baixo";
    } else if (valorRisco >= 3 && valorRisco <= 4) {
      return "Baixo";
    } else if (valorRisco >= 5 && valorRisco <= 9) {
      return "Médio";
    } else if (valorRisco >= 10 && valorRisco <= 14) {
      return "Alto";
    } else if (valorRisco >= 15 && valorRisco <= 25) {
      return "Muito Alto";
    }
    return "Níveis não calculados. Clique novamente!";
  }

  useEffect(() => {
    if (impacto && probabilidade) {
      setClassificacao(calcularRiscoEClassificar(probabilidade, impacto))
    }
  }, [impacto, probabilidade]);
 
  useEffect(() => {
    if(loadOptions) {
      axios.get(`/risks-client.json`).then((res) => {
        setOptions(res.data);
      }).catch((error) => {
        console.log("Ocorreu um erro na requisição,", error)
      }) 
      setLoadOptions(false)
    }
  }, [loadOptions])


  useEffect(() => {
    if (selecaoRisco) {
      Promise.all(selecaoRisco.map(risco => 
        axios.get(`/risks-client/${risco.value}?id_activity=${id}`)
      ))
      .then(responses => {
        const newInfoRisco = responses.map(res => res.data);
        setInfoRisco(newInfoRisco);
      })
      .catch(error => {
        console.log('Não foi possível obter as informações do risco');
      });
    }
  }, [selecaoRisco]);

  useEffect(() => {
    if (selecaoRisco) {
       axios.get(`/activity_processes/${id}`).then(response => {
        setInfoRiscoActivity(response.data.risk_client);
      })
      .catch(error => {
        console.log('Não foi possível obter as informações de riscos da atividade');
      });
    }
  }, [selecaoRisco]);

  useEffect(() => {
    if(infoRiscoActivity.length > 0) {
      infoRiscoActivity.map((risco) => {
        handleButtonClick(risco.risk_client_id, 'impact', risco.impact)
        handleButtonClick(risco.risk_client_id, 'probability', risco.probability)
      })
    }
  }, [infoRiscoActivity])


  useEffect(() => {
    setKey(Date.now());
    if(infoRisco[0]?.mitigating_actions){
      setKey(Date.now());
    }
  }, [infoRisco]);
  
 

  useEffect(() => {
    if (Object.values(selectedButton).length === 0) return;

    const newSelectedButtonsState = {};

    if (infoRiscoActivity.length > 0 && updateButton) {
      infoRiscoActivity.forEach(item => {
        const clientId = item.risk_client_id.toString();
        newSelectedButtonsState[clientId] = {
          "impact": item.impact,
          "probability": item.probability
        };
      });

      const hasNullValues = Object.values(newSelectedButtonsState).some(value => value.impact == null && value.probability == null);

      if (!hasNullValues) {
        setSelectedButton(prevState => {
          if (!areObjectsEqual(prevState, newSelectedButtonsState)) {
            return newSelectedButtonsState;
          } else {
            return prevState;
          }
        });
      }
    }
  }, [infoRiscoActivity, selectedButton]);

  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };



  const handleAddField = (index) => {
    setCampos([...campos, selecaoRisco]);
    accordionState(index)
  };

  const handleRemoveField = (index) => {
    const newFields = campos.filter((_, i) => i !== index);
    const newSelecao = selecaoRisco.filter((_, i) => i !== index);
    setCampos(newFields);
    setSelecaoRisco(newSelecao)
    setParamsActions([0]);
  };

  const handleChange = (index, event) => {
    const newFields = campos.map((campo, i) => {
      if (i === index) {
        return { ...campo, valor: event.target.value };
      }
      return campo;
    });
    setCampos(newFields);
  };

  const editClassificacao = () => {
    confirm("É necessário salvar este risco na atividade antes de edita-lo.")
  }


  const handleButtonClick = (riskId, type, level) => {
    setSelectedButton(prevState => ({
      ...prevState,
      [riskId]: {
        ...prevState[riskId],
        [type]: prevState[riskId] && prevState[riskId][type] === level ? null : level
      }
    }));
  };

  const buttonStyle = (riskId, type, level) => ({
    backgroundColor: selectedButton[riskId] && selectedButton[riskId][type] === level ? 'orange' : 'transparent'
  });

  const updateClassificacaoRisco = (nivel, id_risk, field) => {
    setUpdateButton(false)
    axios.put(`/activity_processes/${id}/update_risk`, { idRisk: id_risk, nivel: nivel, field: field })
    handleButtonClick(id_risk, field, nivel)
  }

  const detalhesRisco = (index, id_risk) => {
    const ids = risk_client_ids.map(obj => obj["value"])
    const show = ids.includes(id_risk);
    return (
      <>
        <div>Detalhes do risco</div>
        <div> <p style={{color: '#9fa4a9'}}>Descrição: {infoRisco[index]?.description}</p> </div>
        <div> <p style={{color: '#9fa4a9'}}>Observação: {infoRisco[index]?.obs}</p> </div>
        <div>
        {show ?
          <>
            <div>
              <h4 style={{fontSize: 18}}>Impacto</h4>
              {Object.keys(niveisImpacto).map((nivel) => (
                <button
                  key={infoRiscoActivity[index]?.impact}
                  type="button"
                  className={'btn btn-default'}
                  style={buttonStyle(id_risk, 'impact', nivel)}
                  onClick={() => updateClassificacaoRisco(nivel, id_risk, 'impact')}
                >
                  {nivel}
                </button>
              ))}
            </div>
            <div>
              <h4 style={{fontSize: 18}}>Probabilidade</h4>
              {Object.keys(niveisProbabilidade).map((nivel) => (
                <button
                  key={infoRiscoActivity[index]?.probability}
                  type="button"
                  className={'btn btn-default'}
                  style={buttonStyle(id_risk, 'probability', nivel)}
                  onClick={() => updateClassificacaoRisco(nivel, id_risk, 'probability')}
                >
                  {nivel}
                </button>
              ))}
            </div>
          </>
          : <div>
              <h4 style={{fontSize: 18}}>Impacto: <button className="btn btn-default" style={{backgroundColor: 'orange'}}>{infoRisco[index]?.impacto}</button></h4>
              <h4 style={{fontSize: 18}}>Probabilidade: <button className="btn btn-default" style={{backgroundColor: 'orange'}}>{infoRisco[index]?.probability}</button></h4>
          </div>}
          <div>
            <div className="row"> 
              <p style={{color: '#9fa4a9', marginTop: 10}}>
                Classificação de risco: {infoRisco[index]?.risk_classficication}
              </p>
              {!show ?
              <button type="button" onClick={() => editClassificacao()} style={{height: 30, marginTop: 10, marginLeft: 10}} className="btn btn-default btn-sm">Editar classificação</button> : ''}
           </div>
          </div>
          <input style={{display: 'none'}} name={"activity_process[risk_client_ids][]"} value={selecaoRisco[index]?.value}></input>
        </div>
        <div>
          <h5>Ação Mitigadora</h5>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{width: '90%'}}>
              <MultSelect 
                key={id}
                endpoint={'action_mitigating_clients.json'} 
                selected={infoRisco[index]?.mitigating_actions }
                selectedDefault={infoRisco[index]?.activity_mitigating_actions}
                resource={`risks-client-mitigatings/${infoRisco[index]?.id}`}
                id={id} 
                idRisco={infoRisco[index]?.id}
                client_id={client_id}
                action={action}
                field={'mitigating_action_ids'} 
                isMult={true}
                setParamsActions={setParamsActions}
                paramsActions={paramsActions}
                setCurrentRisco={setCurrentRisco}
              />
            </div>
           <ModalActionMitigating />
          </div>
        </div>
      </>
    )
  }


  return (
    <>
      <div style={{padding: 20}}>
      {campos.map((campo, index) => (
          <div key={index} style={{width: '100%'}}>
            <hr />
            <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}} onClick={() => accordionState(index)}>
              <h4>Risco {index+1}</h4>
              <i className={`fa fa-chevron-${accordionStates == index ? "down" : "right"}`} />
            </div>
              <>
                <ContentSelectRisco>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div style={{width: '90%'}}>
                        <Select
                          key={index}
                          placeholder={"Selecione uma opção"}
                          options={options}
                          value={selecaoRisco[index]}
                          onChange={(option) => setSelecaoRisco(oldOption => {
                            if (index < oldOption.length) {
                              return oldOption.map((item, idx) => idx === index ? option : item);
                            }
                            return [...oldOption, option];
                          })}
                          onMenuOpen={() => setLoadOptions(true)}
                        />
                      </div>
                      <ModalRiskClient impacts={impacts} probabilities={probabilities} />
                    </div>
                  {detalhesRisco(index, campo)}
                </ContentSelectRisco>
                <button style={{marginTop: 10}} className="btn btn-block btn-outline-danger btn-sm" type="button" onClick={() => handleRemoveField(index)}>
                  Remover
                </button>
              </> 
          </div>
        ))}
        <button type="button" style={{marginTop: 10}} className="btn btn-outline-secondary" onClick={handleAddField}>
          Adicionar Risco
        </button>
      </div>
      {selecaoRisco.map((index, selecao) => (
        index ? 
        <>
          <input key={index} type="hidden" name="activity_process[risk_client_ids][]" value={index.value} /> 
          <input key={index} type="hidden" name="actions_mitigating[]" value={JSON.stringify(paramsActions)} /> 
        </>
        : null
      ))}
    </>);
  }

export default RiskManagement;

const ContentSelectRisco = styled.div`
  margin-bottom: 10px;
`

