import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2';

export default class extends Controller {
	static targets = ["modal", "form", "name"]
	static values = { url: String }


	connect(){
		console.log(this.modalTarget)
	}

	close() {
		const elementToClose = this.element.querySelector('[data-dismiss="modal"]');
    if (elementToClose) {
      elementToClose.click();
    }
	}

	clearForm(form) {
    const inputs = form.querySelectorAll("input, textarea");
    inputs.forEach(input => {
      input.value = "";
    });
  }

	
  showSuccessAlert() {
    Swal.fire({
      icon: 'success',
      title: 'Envio bem-sucedido!',
      text: 'Seu formulário foi enviado com sucesso.',
    });
  }

  showErrorAlert() {
    Swal.fire({
      icon: 'error',
      title: 'Erro no envio!',
      text: 'Ocorreu um erro ao enviar o formulário.',
    });
  }

	async submit(event){
    event.preventDefault();
		try {
			const modal = this.modalTarget;
			const resource = this.urlValue
			const form = modal.querySelector("form");
			
			const response = await fetch(`${resource}`, {
		  	method: 'POST',
		  	body: new FormData(form)
      });
			if(response.ok){
				this.close()
				this.clearForm(form)
				this.showSuccessAlert()
		    document.dispatchEvent(new CustomEvent("loadOptions"));
        } else {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            form.classList.add('was-validated');
          } else {
				    this.close()
				    this.showErrorAlert()
          }
			}
		} catch(error) {
			console.log("Erro ao enviar formulario:", error)
		}
			
	}
}
