import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["expanded"];
  static values = { rowId: String };

	connect() {
		this.expandedTargets.map((td) => {
			td.hidden = true
		})
  }

  open(event) {
    const row = event.currentTarget; // Obtenha o elemento de linha clicado.
		

    if (row) {
      const rowId = row.id;

      const expandedTargetsInRow = this.expandedTargets.filter(
        (element) => element.id == `row-${rowId}`
      );


      expandedTargetsInRow.forEach((element) => {
        element.hidden = !element.hidden;
      });
    }
  }
}

