import React from 'react';

const HelloWorld = () => {
  return (
    <div>Hello React</div>
  )
}

HelloWorld()

export default HelloWorld;
