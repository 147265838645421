import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ['probability', 'impact', 'labelOutput'];
	static values = { url: String }

	connect(){
		this.updateLabel()
	}

	handleCustomEvent(event) {
    console.log("Evento personalizado acionado a partir do Controller A:", event);
  }

  async fetchData(resource, id) {
		if(id === '') { return }
    if (id === '') {
      throw new Error("ID não fornecido");
    }

    const uri = `/${resource}/${id}.json`;

    const response = await fetch(uri);

    if (!response.ok) {
      throw new Error("Erro ao buscar informações do servidor");
    }

    return response.json();
	}

	async calculateClassificationRisk(id1, id2) {
		if(id1 === '') {return}
	
		const resource = this.urlValue

		console.log(resource)
		
		const urlImpact =  resource == '/risks' ? 'impacts' : 'impact_clients'
		const urlProbability = resource == '/risks' ? 'probabilities' : 'probability_clients'

    try {
      const data1 = await this.fetchData(`${urlImpact}`, id1);
			const data2 = await this.fetchData(`${urlProbability}`, id2);

      const value1 = data1.value;
      const value2 = data2.value;

      return value1 * value2;
    } catch (error) {
      console.error("Erro ao calcular o risco de classificação:", error);
    }
  }

	updateLabel() {
		const idImpact = this.impactTarget.value;
		const idProbability = this.probabilityTarget.value;

		
		(async () => {
			try {
				const valueClassification = await this.calculateClassificationRisk(idImpact, idProbability);

				let label = "";

				if (valueClassification >= 1 && valueClassification <= 6) {
					label = "Muito Baixo";
				} else if (valueClassification >= 7 && valueClassification <= 13) {
					label = "Moderado";
				} else if (valueClassification >= 14 && valueClassification <= 20) {
					label = "Alto";
				} else if (valueClassification >= 21 && valueClassification <= 25) {
					label = "Muito Alto";
				} else {
					label = "Classificação não prevista";
				}

				this.labelOutputTarget.textContent = label;
				this.updateColor(label)
			} catch (error) {
				console.error("Erro ao calcular e exibir a classificação:", error);
			}
		})();

	}

	updateColor(label){
		const colors = {"Muito Alto": "bg-danger", "Alto": "bg-warning", "Moderado": "bg-info", "Baixo": "bg-success", "Muito Baixo": "bg-success"}
		this.labelOutputTarget.classList.remove("bg-danger", "bg-warning", "bg-info", "bg-success")
		this.labelOutputTarget.classList.add(colors[label]);
	}
}
