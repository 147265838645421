import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  	static targets = ['select'];
		static values = {
			 url: String,
			 selected: Number
		 }

   connect() {
			document.addEventListener("loadOptions", () => {
				this.load();
			});
  	}

	  async load() {
			try {
				const resource = this.urlValue;
				const baseUrl = window.location.origin;
		
				const response = await fetch(`${baseUrl}${resource}.json`);

				if (!response.ok) {
					throw new Error(`Request failed with status: ${response.status}`);
				}
		
				const data = await response.json();
		
				this.selectTarget.innerHTML = '';
		
				data.forEach(option => {
					const optionElement = document.createElement('option');
					optionElement.value = option.id;
					optionElement.text = option?.name || option?.description;
					this.selectTarget.appendChild(optionElement);
        });
        console.log(data)
			} catch (error) {
				console.error('Error loading options:', error);
			}
	}
	
}
