import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
  }

  applyDocumentMask() {
		let checked = document.getElementsByName("client[client_type]")
		if (checked.length == 0) {
			checked = document.getElementsByName("provider[type]")
		} 

		if (checked.length == 0){
			checked = document.getElementsByName("provider_client[type_provider]")
		}
		for (var i = 0; i < checked.length; i++) {
    	if (checked[i].checked) {
      	var tipoCliente = checked[i].value;
      	break;
    	}
  	}
    const value = this.element.value.replace(/\D/g, "");

    if (tipoCliente == 'PF') {
      this.element.value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
    } else if (value.length <= 14 && tipoCliente == "PJ") { 
      this.element.value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5');
    } else{ 
      this.element.value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5');
    }
  }

  applyCepMask() {
		const cep = this.element.value.replace(/\D/g, "");

		if (cep.length == 9) {
			this.element.value = cep.replace(/^(\d{5})(\d{3}).*/, "$1-$2"); 
		} else {
			this.element.value = cep.replace(/^(\d{5})(\d{3}).*/, "$1-$2"); 
    }
  }

  applyPhoneMask() {
		const phone = this.element.value.replace(/\D/g, "");

    if(phone.length == 11) {
		  this.element.value = phone.replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3"); 
    } else {
		  this.element.value = phone.replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3"); 
    }
  }

  applyCpfMask(){
		const cpf = this.element.value.replace(/\D/g, "");
    
    if(cpf.length == 11) {
      this.element.value = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
    } else {
      this.element.value = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
    }
  }
}

