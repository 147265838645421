import React,{ useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Notify from '../utils/Notify.js'


const ModalDataCategory = () => {
  const [show, setShow] = useState(false);
  const inputRefs = [useRef(), useRef()];

  const handleKeyDown = (event, index) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        const nextIndex = (index + 1) % 2; 
        inputRefs[nextIndex]?.current?.focus(); 
      }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    active: 'ativo',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const cleanFormData = () => {
    console.log(Object.keys(formData))
    Object.entries(formData).map((f) => {
      setFormData((prevData) => ({ ...prevData, [f[0]]: ''}))
    })
  }

  const save = async (e) => {
    try {
      const response = await axios.post('/data_categories', {data_category: formData});
      Notify("success")
      handleClose();
      cleanFormData();
    } catch (error) {
      console.error('Erro ao criar o registro:', error);
      Notify("error", error.message)
    }
  };

  return (
    <>
      <Button variant="outline-secondary" onClick={handleShow}>
        + Novo
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Categoria de Dados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                ref={inputRefs[0]}
                onKeyDown={(e) => handleKeyDown(e, 0)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Descrição</Form.Label>
              <Form.Control as="textarea" 
                rows={3} 
                name="description"
                value={formData.description}
                ref={inputRefs[0]}
                onKeyDown={(e) => handleKeyDown(e, 0)}
                onChange={handleInputChange}          
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={() => save()}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalDataCategory;
