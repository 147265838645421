import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import axios from 'axios';
import ModalRiskClient from './modais/ModalRiskClient.js'

const Selecionar = ({endpoint, selected, id, resource, action, field }) => {
  const [options, setOptions] = useState([]);  
  const [value, setValue] = useState(selected); 
  const [loadOptions, setLoadOptions] = useState(true)

  const onChange = (option) => {
    setValue(option);
  //  if (option && option.value) {
  //    axios.put(`/${resource}/${id}`, { collaborator: { [field]: option?.value } })
  //      .then((res) => {
  //      })
  //      .catch((error) => {
  //        console.error("Ocorreu um erro durante a atualização:", error);
  //      });
  //  }
  }

  useEffect(() => {
    if(action === 'new'){
      setValue(null)
    }
  }, [action])

  useEffect(() => {
    if(loadOptions) {
      axios.get(`/${endpoint}`).then((res) => {
        setOptions(res.data);
      }).catch((error) => {
        console.log("Ocorreu um erro na requisição,", error)
      }) 
      setLoadOptions(false)
    }
  }, [loadOptions])
    
  return (
    <div>
      <Select
        placeholder={"Selecione uma opção"}
        options={options}
        value={value}
        onChange={onChange}
        onMenuOpen={() => setLoadOptions(true)}
      />
      <input style={{display: 'none'}} name={field} value={value?.value}></input>
    </div>
  )
}

export default Selecionar;
