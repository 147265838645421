import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["label", "fantasy"];

  connect() {
    this.setLabel();
  }

  changeLabel() {
    this.setLabel();
  }

  setLabel() {

    let checked = document.getElementsByName("client[client_type]");
    let documentOriginalValue = document.getElementById("document-number-client") || document.getElementById("document")
    let documentValue = '';

    console.log(documentOriginalValue)


    if (checked.length == 0) {
      checked = document.getElementsByName("provider[type]")
    } 

    if (checked.length == 0){
      checked = document.getElementsByName("provider_client[type_provider]")
    }

    let label;
    for (var i = 0; i < checked.length; i++) {
      if (checked[i].checked) {
        var tipoCadastro = checked[i].value;
        break;
      }
    }

    if (tipoCadastro == "PF" && documentValue === '') {
      label = "Nome";
      this.fantasyTarget.hidden = true
    } else if (tipoCadastro == "PJ") {
      label = 'Razão Social';
      this.fantasyTarget.hidden = false
    } 
    this.labelTarget.textContent = label
  }
}

