import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['active']
  static targets = ['btn', 'tab']
  static values = { 
		defaultTab: String,
	}

  connect() {
    this.tabTargets.map((x) => (x.hidden = true))
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);
		const query = params.get("query");

		this.defaultTabValue = query || query == '' || params.get("page") ? 'tab2' : 'tab1'
		console.log(this.defaultTabValue)

    try {
      let selectedBtn = this.btnTargets.find((element) => element.id === this.defaultTabValue)
      let selectedTab = this.tabTargets.find((element) => element.id === this.defaultTabValue)

      selectedTab.hidden = false
      this.addClassesToElement(selectedBtn, this.activeClasses)
    } catch {}
  }

	select(event) {
    let selectedTab = this.tabTargets.find((element) => element.id === event.currentTarget.id)
    if (selectedTab.hidden) {
      this.tabTargets.map((x) => (x.hidden = true)) 
      this.btnTargets.map((x) => this.removeClassesFromElement(x, this.activeClasses)) 

      selectedTab.hidden = false 
      this.addClassesToElement(event.currentTarget, this.activeClasses) 
		}
  }

  addClassesToElement(element, classes) {
    classes.map((c) => element.classList.add(c))
  }

  removeClassesFromElement(element, classes) {
    classes.map((c) => element.classList.remove(c))
  }

	search(event){
		this.defaultTabValue = 'tab2'
	}
}

