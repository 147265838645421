import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="preview-img"
export default class extends Controller {
	static targets = ['src']

	showImg(event) {
		if(event.target.files.length > 0){
      const file = event.target.files[0]
      if(file.size > 900 * 1024){
        alert('O arquivo deve ser menor que 900kb.');
        document.getElementById('user_photo').value = ""
        return;
      }
			const src = URL.createObjectURL(event.target.files[0]);
			const preview = document.getElementById("file-ip-1-preview");
			preview.src = src;
			preview.style.width = "250";
			preview.style.height = "250";
			preview.style.display = "block";
			this.ocultCurrentImg()
		}
  }

	ocultCurrentImg(){
		this.srcTarget.style.display = 'none'
	}
}
