import React,{ useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Notify from '../utils/Notify.js'


const ModalActionMitigating = ({isAdmin}) => {
  const [show, setShow] = useState(false);
  const inputRefs = [useRef(), useRef()];

  const handleKeyDown = (event, index) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        const nextIndex = (index + 1) % 2;
        inputRefs[nextIndex].current.focus();
      }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    is_admin: isAdmin
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const maxLength = name === 'name' ? 150 : 0;

    const truncatedValue = maxLength > 0 ? value.slice(0, maxLength) : value;

    setFormData({
      ...formData,
      [name]: truncatedValue,
    });
  };


  const cleanFormData = () => {
    Object.entries(formData).forEach(([key, value]) => {
      if (key !== 'is_admin') {
        setFormData(prevData => ({ ...prevData, [key]: '' }));
      }
    });
  };


  const save = async (e) => {
    try {
      const response = await axios.post('/mitigating_actions', {mitigating_action: formData});
      Notify("success")
      handleClose();
      cleanFormData();
    } catch (error) {
      console.error('Erro ao criar o registro:', error);
      Notify("error", error.message)
    }
  };

  return (
    <>
      <Button variant="outline-secondary" onClick={handleShow}>
        + Novo
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de Ação Mitigadora</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                name="name"
                value={formData.name}
                ref={inputRefs[0]}
                onKeyDown={(e) => handleKeyDown(e, 0)}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Descrição</Form.Label>
              <Form.Control as="textarea"
                rows={3}
                name="description"
                ref={inputRefs[1]}
                value={formData.description}
                onChange={handleInputChange}
                onKeyDown={(e) => handleKeyDown(e, 1)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
              style={{display: 'none'}}
            >
              <Form.Label>Descrição</Form.Label>
              <Form.Control as="textarea"
                rows={3}
                name="is_admin"
                value={isAdmin}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={() => save()}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalActionMitigating;
