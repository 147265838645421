import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["impactButton", "probabilityButton", "resultLabel"];

  connect() {
    this.updateResultLabel();
  }

  toggle(event) {
    event.target.classList.toggle("active");
    this.updateResultLabel();
  }

  updateResultLabel() {
    const selectedImpacto = this.impactButtonTargets.find(button => button.classList.contains("active"));
    const selectedProbabilidade = this.probabilityButtonTargets.find(button => button.classList.contains("active"));

    if (selectedImpacto && selectedProbabilidade) {
      const impactoValue = selectedImpacto.getAttribute("data-impacto");
      const probabilidadeValue = selectedProbabilidade.getAttribute("data-probabilidade");

      this.resultLabelTarget.textContent = `Impacto: ${impactoValue}, Probabilidade: ${probabilidadeValue}`;
    } else {
      this.resultLabelTarget.textContent = "Selecione ambos os valores.";
    }
  }
}

