import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  add(event) {
	  console.log(
		  {event}
	  )
    event.preventDefault();
    const association = event.target.attributes['links'];
    const content = event.target.attributes['data-fields'];
    content.insertAdjacentHTML('beforebegin', content);
  }

  remove(event) {
    event.preventDefault();
    const wrapper = this.element.closest('.nested-fields');
    if (wrapper.dataset.newRecord) {
      wrapper.remove();
    } else {
      this.element.querySelector('input[name*="_destroy"]').value = '1';
      wrapper.style.display = 'none';
    }
  }
}

