import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["labelToUpdate"];

  connect() {
    this.updateLabel();
  }

  updateLabel() {
    const type = this.data.get("type");
    const labelToUpdate = this.labelToUpdateTarget;

    if (type === "PF") {
      labelToUpdate.textContent = "Nome:";
    } else if (type === "PJ") {
      labelToUpdate.textContent = "Razão Social:";
    }
  }
}


