import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

	connect(){
	}

  toggleAll(event) {
    const isChecked = event.target.checked;
		console.log(isChecked)
		console.log(this.checkboxTargets)
		if(isChecked) {
			this.checkboxTargets.forEach((checkbox) => {
      	checkbox.checked = true;
    	});
		} else {
			this.checkboxTargets.forEach((checkbox) => {
      	checkbox.checked = false;
    	});
		}
    
  }
}

